<template>
  <base-section id="Login">
    <v-container
            fluid
        >
            <v-layout
            align-center
            justify-center
            >
            <v-flex
                xs12
                sm8
                md4
            >
                <!-- <img src="../public/logo.png" width="97%"> -->
                <v-card class="elevation-12" outlined>
                <v-toolbar
                    color="primary"
                    dark
                    flat
                >
                    <v-toolbar-title>{{loading_title}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                        <v-icon>mdi-key</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-form v-model="form_users" ref="form" lazy-validation>
                    <v-text-field 
                        prepend-icon="mdi-account" 
                        v-model="users_id" 
                        :rules="users_id_rules"
                        required 
                        label="ID"
                        type="text"
                        autofocus>
                    </v-text-field>
                        <v-text-field
                        prepend-icon="mdi-lock"
                        v-model="users_password"
                        :rules="users_password_rules"
                        required
                        :append-icon="users_password_hint ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="() => (users_password_hint = !users_password_hint)"
                        :type="users_password_hint ? 'text' : 'password'"
                        label="Password"
                        id="password"
                        v-on:keyup.enter="Login">
                        </v-text-field>
                    </v-form>
                    <v-btn color="secondary" rounded block @click="Login">Login</v-btn>
                </v-card-text>
                <!-- <v-card-actions>
                    <span class="red--text">Version {{app_version}}</span>
                    <v-spacer></v-spacer>
                    <span @click="ForgotPassword">Forget Password</span>
                </v-card-actions> -->
                </v-card>
            </v-flex>
            </v-layout>
        </v-container>
        <v-snackbar 
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            v-model="snackbar.dialog"
            rounded = "pill"
            bottom
            >
            {{ snackbar.text }}
            <template>
            <v-btn text @click.native="snackbar.dialog = false">Close</v-btn>
            </template>
        </v-snackbar>
  </base-section>
</template>

<script>
  export default {
    name: 'Login',
    data: () => ({
      check_login: null,
      dialog_forgot_password: false,
      //SnackBar
      snackbar: {
        dialog: false,
        color: 'success',
        text: '',
        timeout: 3000,
      },
      //Validation Form Users
      loading_title: '',
      feeds:[],
      form_users: true,
      users_id: '',
      users_id_rules: [
        v => !!v || 'ID is required'
      ],
      users_password: '',
      users_password_rules: [
        v => !!v || 'Password is required'
      ],
      users_password_hint: false,
      form: {
        password: '',
        password_show: false,
        password_rules: [(v) => !!v || "Please fill Password"],
      }
    }),
    provide: {
      heading: { align: 'center' },
    },
    created () {
      this.FirstLoad()
    },
    methods: {
        FirstLoad () {
            this.loading_title   = 'Login'
        },
        Login () {
            if (this.$refs.form.validate()) {
                this.ProcessLogin(this.users_id, this.users_password)
            }
        },
        ProcessLogin (usersid, userspassword) {
        //Call API Users
        this.loading_title = 'Loading...'
        let formdata = {
            url_type: 'login',
            web_member_id: usersid,
            web_member_mobile: userspassword,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiWebSubscriber'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
            .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === 'Y') {
                    localStorage.setItem('website_users_id', usersid)
                    localStorage.setItem('website_users_otherid', feedback[0].feedback_users_otherid)
                    localStorage.setItem('website_users_othername', feedback[0].feedback_users_othername)
                    this.SnackBar(true, 'success', 'Login Sukses', 0)
                    this.$router.push({name: feedback[0].feedback_page})
                } else {
                    this.SnackBar(true, 'error', 'ID dan Password anda Salah', 0)
                }
            } else {
                this.SnackBar(true, 'error', 'Login Gagal', 0)
            }
            this.loading_title = 'Login'
        })
        .catch(e => {
            this.loading_title = 'Login'
            this.SnackBar(true, 'error', e, 0)
        })
        },
        ForgotPassword () {
            this.dialog_forgot_password = true
            setTimeout(function(){
                document.getElementById('forgotpassword').focus()
            }, 500)
        },
        SnackBar (dialog, color, text, timeout) {
            this.snackbar.dialog  = dialog
            this.snackbar.color   = color
            this.snackbar.text    = text
            this.snackbar.timeout = timeout
        },
    },
  }
</script>
